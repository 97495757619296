<script setup>
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const toast = useToast();
const confirm = useConfirm();
const memberStore = MemberStore();

/**
 * Data
 */
const blockOverlay = ref(false);

const loading = ref(false);
const {exam} = storeToRefs(memberStore);
const timeLeft = ref("");
const submitedExam = ref(false);
const doAssessment = ref(false);
const submitedAssement = ref(false);

let examTimeout = null;

/**
 * Computed
 */


/**
 * Mounted
 */
onMounted(() => {
	const timeout = () => {
		const now = new Date().getTime();
		if (exam.value && exam.value.e > now) {
			const tl = exam.value.e - now;
			timeLeft.value = `${Math.floor(tl * 0.001 * 0.01667)} นาที ${Math.floor(tl * 0.001 % 60)} วินาที`;

			examTimeout = setTimeout(timeout, 1000);
		} else {
			memberStore.examTimeout();
			toast.removeGroup('time-left');

			confirm.require({
				group: "startExam",
				header: "หมดเวลา",
				message: "เวลาในการทำข้อสอบได้หมดลงแล้ว",
				icon: "pi pi-exclamation-triangle",
				acceptClass: 'p-button-danger',
				acceptLabel: "ตกลง",
				rejectLabel: "ยกเลิก",
				accept: () => {
					router.replace({name: "dashboard"});
				},
				reject: () => {
					router.replace({name: "dashboard"});
				},
			});
		}
	};

	if (!memberStore.user?.campus || !memberStore.user?.year) {
		router.replace({name: "profile", query: {continue: "exam"}});
	} else if (!exam.value || exam.value.e < new Date().getTime()) {
		confirm.require({
			group: "startExam",
			header: "เริ่มทำข้อสอบ",
			message: 'เมื่อกด "เริ่มทำข้อสอบ" ระบบจะทำการเริ่มจับเวลาและต้องทำให้เสร็จภายในเวลาที่กำหนด',
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "เริ่มทำข้อสอบ",
			rejectLabel: "กลับ",
			accept: () => {
				blockOverlay.value = true;
				memberStore.startExam().then(() => {
					examTimeout = setTimeout(timeout, 1000);

					toast.add({
						group: "time-left",
						severity:"info",
						summary: "เหลือเวลาอีก",
						detail: timeLeft.value,
						closable: false
					});
				}).finally(() => {
					blockOverlay.value = false;
				})
			},
			reject: () => {
				router.replace({name: "dashboard"});
			}
		});
	} else {
		examTimeout = setTimeout(timeout, 1000);
		toast.add({
			group: "time-left",
			severity:"info",
			summary: "เหลือเวลาอีก",
			detail: timeLeft.value,
			closable: false
		});
	}
});


/**
 * Actions
 */
const submitExam = (evt) => {
	submitedExam.value = true;
	if (exam.value.a.findIndex(a => a === -1) > -1) {
		toast.add({
			severity: "warn",
			summary: "การทำข้อสอบยังไม่เสร็จสมบูรณ์",
			detail: "กรุณาเลือกคำตอบให้ครบทุกข้อ",
			life: 10000
		});
		return;
	}

	confirm.require({
		group: "doneExam",
		target: evt.currentTarget,
		message: "ยืนยันการส่งคำตอบ ?",
		icon: "pi pi-exclamation-triangle",
		acceptLabel: "ส่งคำตอบ",
		rejectLabel: "ไม่ส่งคำตอบ",
		accept: () => {
			loading.value = true;
			toast.removeGroup('time-left');
			clearTimeout(examTimeout);

			memberStore.submitExam().then(() => {
				doAssessment.value = true;
			}).finally(() => {
				loading.value = false;
			})
		},
		reject: () => {
			//
		}
	});
}
const submitAssessment = (evt) => {
	submitedAssement.value = true;
	if (memberStore.assessment.a.findIndex(a => a === 0) > -1) {
		toast.add({
			severity: "warn",
			summary: "การทำแบบประเมินยังไม่เสร็จสมบูรณ์",
			detail: "กรุณาเลือกคำตอบให้ครบทุกข้อ",
			life: 10000
		});
		return;
	}

	confirm.require({
		group: "doneExam",
		target: evt.currentTarget,
		message: "ยืนยันการส่งแบบประเมิน ?",
		icon: "pi pi-exclamation-triangle",
		acceptLabel: "ส่งคำตอบ",
		rejectLabel: "ไม่ส่งคำตอบ",
		accept: () => {
			loading.value = true;

			memberStore.submitedAssement().then(() => {
				router.replace({name: "dashboard"});
			}).finally(() => {
				loading.value = false;
			})
		},
		reject: () => {
			//
		}
	});
}
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep input {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #bbb;
		opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #bbb;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: #bbb;
	}
}

::v-deep .p-card-body {
	padding: 0.5rem 1.5rem;
}
::v-deep.assessment .p-card-body {
	padding: 0.5rem 1rem 0 1rem;
}
</style>

<template>
	<Toast
		group="time-left"
		class="w-15rem"
	>
		<template #message="slotProps">
			<div class="flex">
				<i class="pi pi-exclamation-triangle text-7xl" />
				<div class="text-center ml-3">
					<h5 class="mt-1 mb-2">
						{{slotProps.message.summary}}
					</h5>
					<p>{{timeLeft}}</p>
				</div>
			</div>
    </template>
	</Toast>
	<Toast />
	<ConfirmDialog
		:closeOnEscape="false"
		:closable="false"
		group="startExam"
	/>
	<ConfirmPopup group="doneExam" />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<template v-if="exam">
		<div class="text-left pb-5 pbt-0">
			<Button
				:label="loading ? 'กำลังส่งคำตอบ...' : 'ส่งคำตอบ'"
				:loading="loading"
				@click="submitExam($event)"
			/>
		</div>

		<Card
			v-for="(ex, i) in exam.q"
			:key="`ex-${i}`"
			class="mb-2"
		>
			<template #content>
				<div :class="{'p-error': submitedExam && exam.a[i] === -1}">
					<p class="font-bold">{{ i + 1 }}. {{ ex.q }} ?</p>
					<div
						v-for="(c, j) of ex.c"
						:key="`c-${i}-${j}`"
						class="field-radiobutton ml-3 mb-2"
					>
						<RadioButton
							v-model="exam.a[i]"
							:id="`c-${i}-${j}`"
							:value="j"
						/>
						<label
							:for="`c-${i}-${j}`"
							class="line-height-2"
						>
							{{ c }}
						</label>
					</div>
				</div>
			</template>
		</Card>

		<div class="text-left pb-5 ptv-0">
			<Button
				:label="loading ? 'กำลังส่งคำตอบ...' : 'ส่งคำตอบ'"
				:loading="loading"
				@click="submitExam($event)"
			/>
		</div>
	</template>

	<template v-if="doAssessment">
		<h4>แบบประเมินความพึงพอใจของนักศึกษาต่อระบบการทดสอบที่มีการให้ข้อมูลย้อนกลับโดยใช้คอมพิวเตอร์</h4>
		<h5>เพื่อการวัดสมรรถนะวิชาชีพครู: สาระความรู้การวิจัยเพื่อแก้ปัญหาและพัฒนาผู้เรียน</h5>
		<p>
			คำชี้แจง: ให้ท่านพิจารณาข้อความต่อไปนี้แล้วเลือกลงในช่องที่ตรงกับระดับความพึงพอใจของท่านมากที่สุด  โดยมีระดับความพึงพอใจ  5 ระดับ  ดังนี้
		</p>
		<div class="flex">
			<Rating
				:modelValue="1"
				:stars="5"
				:cancel="false"
				class="mr-4"
			/> หมายถึง น้อยที่สุด
		</div>
		<div class="flex">
			<Rating
				:modelValue="2"
				:stars="5"
				:cancel="false"
				class="mr-4"
			/> หมายถึง น้อย
		</div>
		<div class="flex">
			<Rating
				:modelValue="3"
				:stars="5"
				:cancel="false"
				class="mr-4"
			/> หมายถึง ปานกลาง
		</div>
		<div class="flex">
			<Rating
				:modelValue="4"
				:stars="5"
				:cancel="false"
				class="mr-4"
			/> หมายถึง มาก
		</div>
		<div class="flex mb-6">
			<Rating
				:modelValue="5"
				:stars="5"
				:cancel="false"
				class="mr-4"
			/> หมายถึง มากที่สุด
		</div>

		<div class="text-left mb-2">
			<Button
				:label="loading ? 'กำลังส่งแบบประเมิน...' : 'ส่งแบบประเมิน'"
				:loading="loading"
				@click="submitAssessment($event)"
			/>
			<Button
				v-if="memberStore.user?.assessment"
				label="ข้ามการประเมิน"
				class="ml-2 p-button-warning"
				@click="router.replace({name: 'dashboard'})"
			/>
		</div>

		<Card
			v-for="(a, i) in memberStore.assessment.q"
			:key="`assessment-${i}`"
			class="assessment mb-1"
		>
			<template #content>
				<div :class="{'p-error': submitedAssement && memberStore.assessment.a[i] === 0}">
					<p class="font-bold">{{ i + 1 }}. {{ a }}</p>
					<Rating
						v-model="memberStore.assessment.a[i]"
						:cancel="false"
						:stars="5"
					/>
				</div>
			</template>
		</Card>

		<div class="text-left mt-2">
			<Button
				:label="loading ? 'กำลังส่งแบบประเมิน...' : 'ส่งแบบประเมิน'"
				:loading="loading"
				@click="submitAssessment($event)"
			/>
			<Button
				v-if="memberStore.user?.assessment"
				label="ข้ามการประเมิน"
				class="ml-2 p-button-warning"
				@click="router.replace({name: 'dashboard'})"
			/>
		</div>
	</template>
</template>
